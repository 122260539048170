import { Wrapper, Container, Text } from './style'

const Copyright = () => (
  <Wrapper data-testid="copyright-wrapper">
    <Container maxWidth="lg">
      <Text variant="supportForm">©2021 Creditas. All rights reserved</Text>
    </Container>
  </Wrapper>
)

export default Copyright
