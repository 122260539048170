import { styled } from '@creditas/stylitas'
import { Container as CreditasContainer } from '@creditas/layout'
import { media } from '@creditas-lab/style-utilities'

const Wrapper = styled.footer`
  background-color: #edf0ef;
`

const Container = styled(CreditasContainer)`
  padding: 24px 0 32px;

  ${media.md`
    padding: 64px 0;
  `}
`

const List = styled.footer`
  background-color: #edf0ef;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;

  ${media.md`
    flex-direction: row;
  `}
`

export { Wrapper, Container, List }
