import React from 'react'

const AppStore = ({ ...others }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...others}
  >
    <path
      d="M15.323 10.0352C15.3324 9.31189 15.5245 8.6027 15.8815 7.9736C16.2386 7.34451 16.749 6.81595 17.3652 6.4371C16.9737 5.87802 16.4573 5.41792 15.8569 5.09334C15.2565 4.76876 14.5887 4.58864 13.9065 4.5673C12.4513 4.41455 11.0406 5.43807 10.299 5.43807C9.54315 5.43807 8.40143 4.58247 7.17198 4.60776C6.37674 4.63345 5.60172 4.8647 4.92243 5.27898C4.24314 5.69325 3.68274 6.27643 3.29584 6.97168C1.61991 9.87334 2.87001 14.1378 4.47543 16.4833C5.27866 17.6319 6.21741 18.9148 7.44575 18.8693C8.64775 18.8194 9.09667 18.1028 10.5476 18.1028C11.985 18.1028 12.4062 18.8693 13.6595 18.8404C14.9493 18.8194 15.7619 17.6867 16.537 16.5273C17.1141 15.709 17.5582 14.8045 17.8528 13.8474C17.1035 13.5305 16.464 13 16.0141 12.3221C15.5643 11.6442 15.3239 10.8488 15.323 10.0352Z"
      fill="#1F2D27"
    />
    <path
      d="M12.9555 3.02491C13.6587 2.18068 14.0052 1.09556 13.9213 0C12.8469 0.112846 11.8544 0.626345 11.1417 1.43818C10.7932 1.83479 10.5263 2.29619 10.3562 2.796C10.1862 3.29582 10.1163 3.82426 10.1506 4.3511C10.688 4.35663 11.2196 4.24015 11.7055 4.01043C12.1913 3.78071 12.6187 3.44375 12.9555 3.02491Z"
      fill="#1F2D27"
    />
  </svg>
)

export { AppStore }
