import { styled, css } from '@creditas/stylitas'
import { typography, media } from '@creditas-lab/style-utilities'
import { Container as CreditasContainer } from '@creditas/layout'
import { Typography } from '@creditas/typography'

const Wrapper = styled.section(
  ({ theme }) => css`
    padding: 48px 0 56px;
    background-color: ${theme.colorNeutral90};
  `,
)

const Container = styled(CreditasContainer)``

const Title = styled(Typography)(
  ({ theme }) => css`
    margin-bottom: 8px;
    color: ${theme.colorNeutralWhite};
    ${typography.desktop.titleMD300(theme)}
  `,
)

const Description = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.colorNeutralWhite};
    ${typography.desktop.paragraphMD400(theme)};

    ${media.md`
      ${typography.desktop.paragraphLG300(theme)};
    `}

    a {
      color: ${theme.colorBrandPrimaryDefault};
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  `,
)

export { Wrapper, Container, Title, Description }
