import { styled, mq, css } from '@creditas/stylitas'
import { Typography } from '@creditas/typography'
import { media } from '@creditas-lab/style-utilities'
import { Link } from 'gatsby'
import { fillMediaBase } from 'ui/utils/fillMediaBase'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  width: -webkit-fill-available;

  ${media.md`
    width: auto;
    flex-direction: row;
    align-items: center;
  `}
`

const ItemLink = styled(Link)`
  text-decoration: none;
  margin: 16px;
  flex: 2 0 auto;
  color: #1f2d27;

  ${media.md`
    margin: 16px 24px;
  `}

  &:hover {
    text-decoration: underline;
  }
`

const Title = styled(Typography)(
  () => css`
    letter-spacing: 0.3px;
    font-weight: 700;
    color: #1f2d27;

    ${mq({
      fontSize: fillMediaBase('16px', '14px'),
      lineHeight: fillMediaBase('24px', '20px'),
    })}
  `,
)

export { Wrapper, ItemLink, Title }
