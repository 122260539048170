import React from 'react'
import { node, object } from 'prop-types'
import { ThemeProvider } from '@creditas/stylitas'
import tokens from '@creditas/tokens'
import newTokens from '@creditas/tokens/dist/new-version'
import { Global } from '@creditas/global'
import Header from 'components/Header'
import Metatags from 'components/Metatags'
import Contact from 'components/Contact'
import Footer from 'components/Footer'
import { colorsTokens } from 'ui/utils/typographyTokens'
import { Main, customStyles } from './style'

const propTypes = {
  children: node.isRequired,
  metatags: object,
}

const Layout = ({ metatags, children }) => {
  return (
    <ThemeProvider
      theme={{
        ...tokens,
        ...newTokens,
        ...colorsTokens,
      }}
    >
      <Global custom={customStyles} />
      {metatags && <Metatags {...metatags} />}
      <Header />
      <Main>{children}</Main>
      <Contact
        title="Investor contact"
        description="Contact <a href='mailto: investor-relations@creditas.com'>investor-relations@creditas.com</a> if you have any question or request"
      />
      <Footer />
    </ThemeProvider>
  )
}

Layout.propTypes = propTypes
Layout.displayName = 'Layout'

export { Layout }
