import { styled, mq } from '@creditas/stylitas'
import { media } from '@creditas-lab/style-utilities'
import { fillMediaBase } from 'ui/utils/fillMediaBase'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  justify-content: flex-start;
  width: -webkit-fill-available;
  padding: 16px;

  ${media.md`
    width: auto;
    padding: 0;
    justify-content: flex-end;
  `}
`

const SocialMediaIcon = styled.a`
  text-decoration: none;
  transition: opacity 0.3s ease-in-out;

  ${mq({
    marginLeft: fillMediaBase('0px', '25px'),
    marginRight: fillMediaBase('25px', '0px'),
  })}

  &:hover {
    opacity: 0.6;
  }
`

export { Wrapper, SocialMediaIcon }
