import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { Logo, Menu, ArrowRight, AppStore, PlayStore } from 'components/svgs'
import ContentWrapper from 'components/ContentWrapper'
import { scrollTo } from 'ui/utils/scrollTo'
import {
  Container,
  CreditasContainer,
  DownloadAppContainer,
  ScrollButton,
  ScrollLink,
} from './styles'

const Header = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulCategory(sort: { order: ASC, fields: id }) {
        edges {
          node {
            id
            title
            slug
          }
        }
      }
    }
  `)

  const {
    allContentfulCategory: { edges: categories },
  } = data

  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const toggleMenu = () => {
    setMenuIsOpen(prev => !prev)
  }

  return (
    <Container>
      <CreditasContainer maxWidth="lg">
        <nav>
          <ContentWrapper className="contentWrapper">
            <a
              className="logo"
              href="https://creditas.com"
              target="_blank"
              rel="noreferrer"
            >
              <Logo />
            </a>
            <ul>
              <li className="menu-item-desktop">
                <Link to="/" activeClassName="menu-item-active">
                  Home
                  <ArrowRight />
                </Link>
              </li>
              {categories?.map(({ node: category }) =>
                category.title ? (
                  <li key={category.id} className="menu-item-desktop">
                    <Link
                      to={`/${category.slug}`}
                      activeClassName="menu-item-active"
                    >
                      {category.title}
                      <ArrowRight />
                    </Link>
                  </li>
                ) : null,
              )}
            </ul>

            <div />
            <ScrollButton
              label="Subscribe"
              actionType="scrollTo"
              variant="defaultButton"
              actionHref="#investorForm"
            />
            <Menu className="menu" onClick={toggleMenu} />
          </ContentWrapper>

          {menuIsOpen && (
            <div className="suspended_menu">
              <ul>
                <li>
                  <Link to="/" onClick={() => setMenuIsOpen(false)}>
                    Home
                    <ArrowRight />
                  </Link>
                </li>
                {categories?.map(({ node: category }) =>
                  category.title ? (
                    <li key={category.id}>
                      <Link
                        to={`/${category.slug}`}
                        activeClassName="menu-item-active"
                        onClick={() => setMenuIsOpen(false)}
                      >
                        {category.title}
                        <ArrowRight />
                      </Link>
                    </li>
                  ) : null,
                )}
                <li className="menu-item-desktop">
                  <ScrollLink
                    activeClassName="menu-item-active"
                    onClick={() => {
                      setMenuIsOpen(false)
                      scrollTo('#investorForm')
                    }}
                  >
                    Subscribe
                  </ScrollLink>
                </li>
              </ul>

              <DownloadAppContainer>
                <span>Mais praticidade? Baixe nosso app:</span>
                <div>
                  <div className="app">
                    <a
                      href="https://apps.apple.com/br/app/creditas/id1270180256"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <AppStore />
                    </a>
                  </div>
                  <div className="app">
                    <a
                      href="https://play.google.com/store/apps/details?id=br.com.creditas.mobile&hl=pt"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PlayStore />
                    </a>
                  </div>
                </div>
              </DownloadAppContainer>
            </div>
          )}
        </nav>
      </CreditasContainer>
    </Container>
  )
}

export default Header
