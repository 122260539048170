import React from 'react'

const Menu = ({ ...others }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...others}
  >
    <path d="M22 9H2" stroke="#141414" strokeLinecap="round" />
    <path d="M22 15H8" stroke="#141414" strokeLinecap="round" />
  </svg>
)

export { Menu }
